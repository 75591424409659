/* Common Imports */

import React from "react";

/* Component Imports */

import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useRouter } from "next/router";
import BeegruButton from "../components/common-components/buttons/BeegruButton";
import Layout from "../components/layout-components/Layout";
import StateInterface from "../redux-magic/state-interface";
import { profile_context } from "../redux-magic/sub-state-data/profile-context";
import { bottom_subscribe_buy_beegru_points } from "../redux-magic/sub-state-data-empty/bottom-subscribe-buy-beegru-points";

const IllustrationOrganiser = styled("div")(({ theme }) => ({
	height: "calc(100vh - 4rem)",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
}));

const IllustrationSizer = styled("img")(({ theme }) => ({
	margin: "0rem 0rem 0rem 3rem",
	/* XXS breakpoint */
	[theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
		height: "280px",
		width: "280px",
	},
	[theme.breakpoints.only("xs")]: {
		height: "320px",
		width: "320px",
	},
	[theme.breakpoints.only("sm")]: {
		height: "350px",
		width: "350px",
	},
	[theme.breakpoints.only("md")]: {
		height: "350px",
		width: "350px",
	},
	[theme.breakpoints.only("lg")]: {
		height: "350px",
		width: "350px",
	},
	[theme.breakpoints.only("xl")]: {
		height: "400px",
		width: "400px",
	},
	/* XXL breakpoint */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		height: "400px",
		width: "400px",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	color: theme.palette.mode == "dark" ? "#ffffff" : "#000000",
	fontWeight: "500",
	fontSize: "2.125rem",
	margin: "1.5rem 0rem 0rem 0rem",
	[theme.breakpoints.down("sm")]: {
		fontSize: "1.5rem",
		lineHeight: "2.25rem",
	},
}));

const SubHeading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	margin: "0.5rem 0rem 0.5rem 0rem",
	width: "100%",
	color: theme.palette.mode == "dark" ? "#ffffff" : "#666666",
	fontWeight: "400",
	fontSize: "1.125rem",
	lineHeight: "1.75rem",
	textAlign: "center",
	[theme.breakpoints.down("sm")]: {
		fontSize: "1.125rem",
		lineHeight: "1.6875rem",
	},
}));

const Four0Four = ({
	session,
	theme,
	showInactivityDialog,
	dispatch,
	new_listing_url,
	bottom_buy_subscription,
}: {
	session: any;
	theme: Theme;
	showInactivityDialog: boolean;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	bottom_buy_subscription: StateInterface["bottom_subscribe_buy_beegru_points"];
}) => {
	const router = React.useRef(useRouter()).current;

	const localLang = {
		pagenotfound:
			router.locale === "ar-AE" ? " " : router.locale === "kn-IN" ? "404 ಪುಟ ಕಂಡುಬಂದಿಲ್ಲ" : "404 Page Not Found",
		heading:
			router.locale === "ar-AE"
				? " "
				: router.locale === "kn-IN"
					? "ನೀವು ಪ್ರವೇಶಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಿರುವ ಪುಟವನ್ನು ತೆಗೆದುಹಾಕಿರಬಹುದು, ಅದರ ಹೆಸರನ್ನು ಬದಲಾಯಿಸಲಾಗಿದೆ ಅಥವಾ ತಾತ್ಕಾಲಿಕವಾಗಿ ಲಭ್ಯವಿಲ್ಲ."
					: "The page you are trying to access might have been removed,  had its name changed, or is temporarily unavailable.",
		gotohomepage: router.locale === "ar-AE" ? " " : router.locale === "kn-IN" ? "ಮುಖಪುಟಕ್ಕೆ ಹೋಗಿ" : "Go to home page",
	};

	return (
		<React.Fragment>
			<Layout
				noLayoutFooter
				noLayoutHeader
				session={session}
				showInactivityDialog={showInactivityDialog}
				theme={theme}
				dispatch={dispatch}
				profile_context={profile_context}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				bottom_buy_subscription={bottom_buy_subscription ? bottom_buy_subscription : bottom_subscribe_buy_beegru_points}
			>
				<IllustrationOrganiser>
					<Heading>{localLang.pagenotfound}</Heading>
					<SubHeading>{localLang.heading}</SubHeading>
					<IllustrationSizer
						src="/images/illustrations/404_new.svg"
						alt="Seo"
						width={400}
						height={400}
						loading="eager"
						referrerPolicy="no-referrer"
					/>
					<BeegruButton
						flavor="primary"
						variant="contained"
						href="/"
						type="button"
					>
						{localLang.gotohomepage}
					</BeegruButton>
				</IllustrationOrganiser>
			</Layout>
		</React.Fragment>
	);
};

export default Four0Four;
